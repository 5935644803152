/* HAMBURGER MENU */

.MenuHamburgerContainer{
    position: relative;
    width: 50px;
}
.MenuHamburgerBtn{
    border: none;
    background-color: rgba(121, 121, 121, 0.351);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    border-radius: 5px;
}
.MenuHamburgerImg{
    width: 20px;
    height: 16.66px;
}

/* NAV */

.NavCloseBtnContainer{
    position: relative;
    width: 100%;
    grid-area: close;
    z-index: 3;
}

.NavCloseBtn{
    position: absolute;
    right: 20px;
    top: 20px;
    max-width: 25px;
}
@media screen and (max-width: 480px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
@media only screen 
  and (max-width: 363px){
    .BtnClosePopupContents{
        right: 0px;
    }
  }
@media only screen 
  and (max-width: 480px){

    /* GENERAL */
    .ScrollTo{
        height: 0px;
    }
    .InnerBody {
        overflow-x: hidden;
      }
    .BluGrnGradient{
        background-image: linear-gradient(45deg, #212161  15%, #1F8079 50%, var(--everdevs-grn) 85%);
    }

    .TitleContainer{
        text-align: center;
    }
    .Heading{
        font-size: 34px;
        line-height: 30px;
    }
    .MobiGenericText{
        font-size: 16px;
        line-height: 18px;
    }
    .MobiSemiGenericText{
        font-size: 18px;
        line-height: 22px;
    }
    .SubHeading{
        font-size: 19px;
        line-height: 23px;
    }
    .SmlHeading {
        font-size: 12px;
        line-height: 14px;
    }
    .MiniHeading {
        font-size: 10px;
        line-height: 12px;
    }
    .SectionDescription{
        width: 240px;
        margin-left: 21px;
    }
    .MiniText{
        font-size: 12px;
    }
    .MainContent{
        min-width: 320px;
    }

    /* HEADER */
    
    .HeaderContainer{
        max-width: 480px;
        grid-template-areas:
        'close'
        'header';
        grid-template-columns: auto;
        z-index: 3;
    }
    .HeaderLogoContainer{
        width: 100vw;
        height: 40px;
        margin-bottom: 20px;
    }
    .HeaderLogo{
        margin-left: 0;
    }
    
    .HeaderContent{
        height: fit-content;
        max-width: 480px;
        width: 100%;
        grid-template-areas:
        'logo logo'
        'navbar navbar';
        grid-template-columns: 80px auto;
    }
    .NavbarContainer{
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        scroll-behavior: smooth;
        width: 100vw;
        height: calc(100vh - 60px);
    }
    .NavbarContent{
        grid-template-columns: auto;
        grid-template-rows: 80px 80px 80px 80px 80px 80px 40px;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 40px;
        width: 100vw;
    }
    .NavItem{
        height: 30px;
    }
    .NavText{
        font-size: 18px;
    }
    
    .GridNavUsername{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-areas:
        '. username .'
        'dropdowncont dropdowncont dropdowncont';
        width: fit-content;
    }
    .NavUsername{
        margin: 0;
        height: 80px;
        display: grid;
        row-gap: 15px;
        grid-template-areas:
        'image'
        'text';
    }
    .NavLogoutContainer{
        height: 160px;
    }
    .NavLogout{
        margin-top: 100px;
        margin-left: 0;
    }
    .NavbarImageContainer{
        position: relative;
        height: 60px;
        width: 90vw;
    }
    .NavbarImage{
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        margin-right: 0;
        grid-area: image;
    }
    .NavbarImageLetter{
        margin: 0;
        margin-top: 21px;
        margin-right: 21px;
        font-size: 24px;
    }
    .NavUsernameTextContainer{
        grid-area: text;
        display: block;
        width: 100%;
    }
    .NavUsernameText{
        grid-area: text;
        margin: 0;
        justify-content: center;
    }
    .NavDropdownContents{
        background-color: transparent;
    }
    /* BUTTONS */

    .BtnRight{
        margin: 0;
        margin-left: 8px;
    }

    /* GRID */

    .GridSectionOuter{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-areas:
        '. content .';
        max-width: 480px;
        min-width: 320px;
    }
    
    .DescriptionContainer{
        text-align: center;
        margin-bottom: 30px;
    }
    
    .TitleContainer{
        margin-bottom: 20px;
    }

    /* AUTH */

    .AuthWrapper{
        max-width: 480px;
        min-width: 320px;
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    }

    .AuthContents{
        grid-area: content;
        margin-left: 0;
        margin-right: 0;
    }

    .SignupFormGridContainer{
        max-width: 350px;
        width: 100%;
    }
    .LoginFormGridContainer{
        max-width: 350px;
        width: 100%;
    }
    .AuthSubmitContainer{
        display: grid;
        grid-template-areas:
        '. content .';
        grid-template-columns: 50px auto 50px;
    }
    .AuthSubmit{
        width: 100%;
        grid-area: content;
        margin: 0;
    }

    /* HOME */

    .GridHome{
        grid-template-columns: auto 282px auto;
        grid-area: content;
        margin-top: 125px;
        width: 282px;
    }
    .HomeWrapper{
        max-width: 480px;
        min-width: 320px;
    }

    .background-video {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100vw   !important;
        height: 240px  !important;
    }

    .HomeImageFader{
        grid-template-columns: auto 282px auto;
        background-size: 1192.05px 375px;
        background-color: rgba(0, 0, 0);
    }
    .MainWrapper{
        background-color: black;
        background-position: -450px;
        height: 240px;
        background-size: 1192.05px;
        background-repeat: no-repeat;
        max-width: 480px;
    }

    /* GAMES */

    .GridGames{
        display: grid;
        grid-template-rows: 30px auto auto;
        grid-template-columns: auto 240px auto;
        grid-template-areas:
        '. title .'
        '. image .'
        '. description .';
        grid-area: content;
        margin-top: 25px;
    }
    .GamesTitleContainer{
        text-align: center;
        height: 30px;
    }
    .GamesDescriptionContainer{
        margin-top: 3px;
        text-align: center;
    }
    .GamesButtonContainer{
        text-align: center;
    }
    .ImageGames{
        padding-top: 27px;
        width: 240px;
    }

    .GamesWrapper{
        background-position: center top;
        max-width: 480px;
        min-width: 320px;
        height: 386px;
        background-size: 1512px 420px;
        background-repeat: no-repeat;
    }

    /* DONATE */

    .DonateWrapper{
        max-width: 480px;
        min-width: 320px;
        height: 490px;
    }
    
    .DonateTitleContainer{
        text-align: center;
    }
    
    .DonateDescriptionContainer{
        text-align: center;
    }
    
    .DonateButtonContainer{
        text-align: center;
        height: 34px;
        margin-bottom: 30px;
    }
    
    .ImageDonate{
        width: 132px;
        margin-top: -20px;
        margin-left: 54px;
    }
    
    .GridDonate{
        margin-top: 30px;
        display: grid;
        grid-template-columns: auto 240px auto;
        grid-template-rows: 110px 175px 111px auto;
        grid-template-areas:
        '. title .'
        '. image .'
        '. description .'
        '. button .';
    }

    /* CONTACT */

    .ContactWrapper{
        max-width: 480px;
        min-width: 320px;
    }
   
    .ContactTitle{
        margin-top: 32px;
        margin-bottom: 20px;
    }
    
    .ContactDescription{
        margin-bottom: 12px;
    }
    
    .GridContactExplanation{
        display: grid;
        grid-template-columns: auto 240px auto;
        grid-template-areas:
        '. title .'
        '. description .';
    }
    
    .GridContact{
        display: grid;
        grid-area: content;
    }

    .RightScrollPadding{
        width: 65px;
        grid-area: rightpadding;
    }
    .FounderGrid{
        display: grid;
        grid-template-columns: auto 144px auto;
        grid-template-rows: 176px 33px 20px;
        grid-template-areas:
        '. image .'
        'name name name'
        'title title title';
        width: 222px;
        margin-top: 40px;
    }
    
    .FounderImage{
        grid-area: image;
        text-align: center;
    }
    
    .FounderNameContainer{
        grid-area: name;
    }
    
    .FounderName{
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .FounderTitleContainer{
        grid-area: title;
    }
    
    .FounderTitle{
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .FounderKris{
        grid-area: kris;
    }
    
    .FounderMilton{
        grid-area: milton;
    }
    
    .FoundersGrid{
        display: grid;
        grid-template-columns: auto 222px auto;
        grid-template-rows: auto auto;
        grid-template-areas:
        '. kris .'
        '. milton .';
        margin-bottom: 60px;
    }
    

    /* ABOUT */

    .GridAbout{
        grid-template-columns: auto 240px auto;
        grid-template-areas:
        '. explanation .'
        '. story .'
        '. community .'
        '. why .'
        '. info .';
        margin-left: 0;
        margin-right: 0;
        margin-top: 56px;
    }

    .AboutWrapper{
        max-width: 480px;
        min-width: 320px;
    }
    .AboutExplanation{
        max-width: 240px;
    }
    .OurCommunityContainer{
        max-width: 240px;
        margin-top: 18px;
    }
    .AboutSubHeading{
        font-size: 16px;
    }
    .WhyEverDevsContainer{
        margin-top: 18px;
        margin-bottom: 33px;
    }
    .WhyEverDevsSubHeading{
        font-size: 16px;
    }
    
    .OurStoryContainer{
        max-width: 240px;
        margin-top: 30px;
    }
    
    .WhyEverDevsContainer{
        max-width: 240px;
    }

    .GridEverDevsInfo{
        grid-template-areas:
        'info0'
        'info1'
        'info2'
        'info3'
        'info4'
        'info5';
        row-gap: 30px;
        margin-bottom: 52px;
    }
    .GridEverDevsInfoElement{
        margin-left: 15px;
    }

    /* FOOTER */

    .FooterContainer{
        max-width: 480px;
        min-width: 320px;
        height: 420px;
    }
    .GridFooter {
        grid-area: content;
        margin-left: 0;
        margin-right: 0;
        margin-top: 50px;
        display: grid;
        grid-template-areas:
        '. logo .'
        '. locations .'
        '. stayconnected .'
        '. social .'
        '. legal .'
        '. copyright .';
        grid-template-columns: auto 240px auto;
        grid-template-rows: 52px 78px 58px 86px 32px auto;
    }
    
    .SocialIcon{
        margin-left: 10px;
    }
    
    .FooterLogoContainer{
        text-align: center;
    }
    
    .StayConnectedContainer{
        margin-left: 0;
        position: relative;
    }
    
    .StayConnected{
        position: absolute;
        left: 50%;
        margin-left: -54px;
    }
    
    .LocationsContainer{
        width: 100%;
        position: relative;
    }
    .FooterLocations{
        position: absolute;
        text-align: center;
        left: 50%;
        margin-left: -39px;
        width: 78px;
    }
    
    .SocialContainer{
        margin-left: 0;
        text-align: center;
    }
    
    .CopyrightContainer{
        text-align: center;
    }
    
    .LegalContainer{
        margin-left: 0;
        text-align: center;
    }

        
    

}