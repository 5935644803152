@font-face{
  font-family: 'Neuzeit-Gro-T';
  src: local('Neuzeit-Gro-T'), url(./App/utilities/fonts/NeuzeitGroT-Reg.woff2) format('woff2');
}

@font-face{
  font-family: 'ITC-Avant-Garde-Pro-Bold';
  src: local('ITC-Avant-Garde-Pro-Bold'), url(./App/utilities/fonts/ITCAvantGardePro-Bold.woff2) format('woff2');
}

@font-face{
  font-family: 'ITC-Avant-Garde-Pro-Md';
  src: local('ITC-Avant-Garde-Pro-Md'), url(./App/utilities/fonts/ITCAvantGardePro-Md.woff2) format('woff2');
}

@font-face{
  font-family: 'DIN-Condensed-Bold';
  src: local('DIN-Condensed-Bold'), url(./App/utilities/fonts/DIN-Condensed-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Neuzeit-Gro-T;
  font-smooth: never;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
