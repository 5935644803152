/* GENERIC */
:root{
    --everdevs-grn: rgb(62, 207, 97);
}
html{
    --scroll-behavior: smooth;
    scroll-behavior: smooth;
}

body{
    background-color: rgb(255, 255, 255);
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.NBSP{
    font-family: ITC-Avant-Garde-Pro-Bold;
}

ul{
    list-style-type: none;
}

::placeholder{
    color: rgb(194, 194, 194);
}

input[type=checkbox]{
    max-width: 15px;
}

input:active, input:focus, input:visited{
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
  }
  textarea:active, textarea:focus, textarea:visited{
    outline: none;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover, 
  textarea:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
  }

.MainContent{
    min-height: calc(100vh - 170px);
    min-width: 1024px;
}

.BluGrnGradient{
    background-image: linear-gradient(45deg, rgb(33, 33, 97) 15%, rgb(31, 128, 121) 50%, var(--everdevs-grn) 85%);
}

.BlackBG{
    background-color: black;
}
.GreyBG{
    background-color: #C8C8C8;
}
.GreenBG{
    background-color: var(--everdevs-grn);
}
.LightGreenBG{
    background-color: #D0F4D9;
}
.LightGreyBG{
    background-color: #F4F5F6;
}

.TransparentBG{
    background-color: transparent;
}

.Grn{
    color: var(--everdevs-grn);
}

.RichBlueBG{
    background-color: #3E64CF;
}

.GreyText{
    color: #848484;
}

.DarkText{
    color: #434B57;
}

.Blk{
    color: black;
}

.Wht{
    color: white;
}

.Red{
    color: red;
}

.Heading{
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 38px;
    line-height: 32px;
    font-style: normal;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: -6px;
    letter-spacing: -0.015em;
}

.SubHeading{
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 20px;
    line-height: 25px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.015em;
}

.SubHeading2{
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 18px;
    line-height: 25px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.015em;
}

.SmlHeading{
    font-size: 12px;
    line-height: 14px;
}

.PopupHeading{
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 30px;
    line-height: 35px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.015em;
    margin: 0;
}

.MiniHeading{
    font-size: 10px;
    line-height: 12px;
}

.GenericText{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.015em;
    margin: 0;
}

.Link{
    text-decoration: none;
    background-color: transparent;
}

.SmlText{
    font-family: Neuzeit-Gro-T;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;
}

.MiniText{
    font-family: Neuzeit-Gro-T;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
}

.MicroText{
    font-family: Neuzeit-Gro-T;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    letter-spacing: -0.015em;
}

.NeuzSubHeading{
    font-family: Neuzeit-Gro-T;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: -0.015em;
}

.NoBorder{
    border: hidden;
}

.BlkBorder{
    border: 1px solid black;
}

.ScrollToContainer{
    position: relative;
    width: 0px;
    height: 0px;
}
.ScrollTo{
    position: absolute;
    height: 56px;
    bottom: 0px;
}

.Scrollable{
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.Scrollable::-webkit-scrollbar{
    display: none;
}



.UnScrollable{
    overflow: hidden;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

.ScrollIndicatorContainer{
    position: relative;
    padding-top: 6px;
}
.ScrollIndicatorCircle{
    position: absolute;
    right: calc(50% - 7.5px);
    top: 4px;
    width: 15px;
    height: 15px;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #848484;
}
.ScrollIndicatorArrow{
    margin-left: 4px;
    margin-bottom: 2px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid #848484;
    border-left: 1px solid #848484;
    transform: rotate(-45deg);
}

.CloseDropdownArrow{
    animation: closeArrowSpin 0.5s ease-in-out 0s;
    transform: rotate(0deg);
}

.OpenDropdownArrow{
    animation: openArrowSpin 0.5s ease-in-out 0s;
    transform: rotate(180deg);
}

@keyframes closeArrowSpin {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
        
    }
}
@keyframes openArrowSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
        
    }
}

.ElementFadeIn{
    animation: 0.5s ease-in 0s elementFadeIn;
    opacity: 1;
}

.ElementFadeOut{
    animation: 0.5s ease-in-out 0s elementFadeOut;
}

@keyframes elementFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes elementFadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ESpinner{
    width: 50px;
    animation: 1.2s ease-out 0s infinite eSpinner;
}

@keyframes eSpinner {
    0% {
        transform: rotate(0deg) scale(1);
    }
    15% {
        transform: rotate(-190deg) scale(1.25);
    }
    30% {
        transform: rotate(-380deg) scale(1);
    }
    40% {
        transform: rotate(-385deg);
    }
    60% {
        transform: rotate(-325deg);
    }
    80% {
        transform: rotate(-315deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/* POPUPS */

.PopupContainer{
    position: sticky;
    top: 0;
    height: 0;
    z-index: 4;
}

.Popup{
    height: 100vh;
    width: 100%;
    z-index: 4;
}

.BtnClosePopupContainer{
    position: relative;
    height: 0;
}

.BtnClosePopupContents{
    position: absolute;
    text-align: right;
    height: 22px;
    width: 22px;
    right: -10px;
    top: -10px;
    background-image: url(./images/misc/DefaultCloseBtn.svg);
    background-size: 22px 22px;
}

.BtnClosePopupContents:hover{
    background-image: url(./images/misc/HoverCloseBtn.svg);
}

.BtnClosePopupContents:active{
    background-image: url(./images/misc/ClickedCloseBtn.svg);
}

.BtnClosePopup{
    background-color: transparent;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -10px;
    left: -10px;
}



.BtnBasic{
    width: 133px;
    height: 34px;
    border-radius: 34px;
    margin-right: 8px;
    background-color: transparent;
}

.BtnLink:visited, .BtnLink:link{
    text-decoration: none;
}

.BtnLink:hover{
    color: black;
}

.BtnText{
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.BtnWht{
    border: 1px solid white;
    box-sizing: border-box;
    color: white;
}

.BtnBlk{
    border: 1px solid black;
    box-sizing: border-box;
    color: black;
}
.BtnSubmit{
    width: 133px;
    height: 34px;
    border-radius: 34px;
    margin-right: 8px;
    border: 1px solid var(--everdevs-grn);
    background-color: var(--everdevs-grn);
}

.AuthSubmit:hover, .BtnBasic:hover{
    transition: background-color 0.3s ease-in, border 0.3s ease-in, color 0.3s ease-in;
    border: 1px solid var(--everdevs-grn);
    background-color: var(--everdevs-grn);
    box-sizing: border-box;
    color: black;
}
.BtnSubmit.BtnBlk:hover{
    transition: background-color 0.3s ease-in, border 0.3s ease-in, color 0.3s ease-in;
    border: 1px solid #32A04D;
    background-color: #32A04D;
    box-sizing: border-box;
    color: black;
}

.AuthSubmit.BtnBlk:active, .BtnBasic.BtnBlk:active{
    transition: background-color 0.05s ease-in, border 0.05s ease-in, color 0.05s ease-in;
    border: 1px solid black;
    background-color: black;
    box-sizing: border-box;
    color: white;
}
.BtnSubmit.BtnBlk:active{
    transition: background-color 0.05s ease-in, border 0.05s ease-in, color 0.05s ease-in;
    border: 1px solid #8EE6A3;
    background-color: #8EE6A3;
    box-sizing: border-box;
}

.AuthSubmit.BtnWht:active, .BtnBasic.BtnWht:active{
    transition: background-color 0.05s ease-in, border 0.05s ease-in, color 0.05s ease-in;
    border: 1px solid white;
    background-color: white;
    box-sizing: border-box;
    color: black;
}

/* GRIDS REGION */

.GridSectionOuter{
    display: grid;
    grid-template-columns: auto 1024px auto;
    grid-template-areas:
    '. content .';
    width: 100%;
}

.GridExplanation{
    grid-area: explanation;
    display: grid;
    width: 282px;
}

.GridImage{
    grid-area: image;
}

.DescriptionContainer{
    margin-top: 0;
    margin-bottom: 30px;
}

.TitleContainer{
    margin-bottom: 22px;
}

/* FORMS */

.Field{
    padding-left: 15px;
    border: none;
    border-radius: 36px;
    height: 32px;
}
.NoSelectField{
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.FormTextArea{
    height: 75px;
}

.FormGridItem1{
    grid-area: formfieldone;
}
.FormGridItem2{
    grid-area: formfieldtwo;
}
.FormGridItem3{
    grid-area: formfieldthree;
}
.FormGridItem4{
    grid-area: formfieldfour;
}
.FormGridItem5{
    grid-area: formfieldfive;
}
.FormGridItem6{
    grid-area: formfieldsix;
}
.FormGridItem7{
    grid-area: formfieldseven;
}
.FormGridItem8{
    grid-area: formfieldeight;
}

/* AUTH */

.AuthContents{
    grid-area: content;
    background-color: rgb(19, 19, 19);
    max-width: 350px;
    margin-left: 337px;
    margin-right: 337px;
    margin-top: 10vh;
    border-radius: 6px;
}

.AuthLogoContainer{
    padding-top: 30px;
    text-align: center;
}

.AuthLogo{
    padding-top: 10px;
    width: 90px;
}
.PwdResetTitleContainer{
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
}
.PwdResetTitle{
    padding-bottom: 7.5px;
    padding-top: 7.5px;
    font-weight: 500;
    font-size: 16px;
    font-family: Neuzeit-Gro-T;
    margin: 0;
}

.PwdResetEmailContainer{
    display: grid;
    grid-template-areas:
    '. content .';
    grid-template-columns: 50px auto 65px;
    text-align: center;
    padding-bottom: 15px;
}

.PwdResetEmail{
    width: 100%;
    grid-area: content;
}

.AuthOptionsContainer{
    padding-top: 30px;
    padding-bottom: 20px;
    display: flex;
}

.AuthOptionContainer{
    flex: 1;
}

.AuthOptionContainer:first-child{
    text-align: right;
    margin-right: 10px;
}

.AuthOptionContainer:last-child{
    text-align: left;
    margin-left: 10px;
}

.AuthOption{
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    font-family: Neuzeit-Gro-T;
    height: 30px;
    padding: 0;
}

.AuthSubmit{
    margin-left: 50px;
    width: 250px;
    height: 34px;
    border-radius: 34px;
    margin-right: 8px;
    background-color: transparent;
}
.BtnForgotPwd{
    background-color: transparent;
    border: none;
}
.BtnPwdReset{
    margin-top: 15px;
}
.PopupFooterContainer{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 40px;
    margin-top: 5px;
}

.ForgotPw{
    line-height: 20px;
}

.AuthForm{
    padding-top: 10px;
    max-height: calc(100vh - 242px);
}

.AuthTitleContainer{
    margin-left: 20px;
}

.FormPolicy{
    padding-top: 5px;
    text-align: left;
}

.SignupFormGridContainer{
    display: grid;
    grid-template-columns: 50px 30px auto 50px;
    grid-template-rows: auto;
    grid-template-areas:
      ". formfieldone formfieldone ."
      ". formfieldtwo formfieldtwo ."
      ". formfieldthree formfieldthree ."
      ". formfieldfour formfieldfour ."
      ". formfieldfive formfieldsix ."
      ". formfieldseven formfieldeight .";
    row-gap: 15px;
    width: 350px;
    margin-bottom: 20px;
}
.LoginFormGridContainer{
    display: grid;
    grid-template-columns: 50px 30px auto 50px;
    grid-template-rows: auto;
    grid-template-areas:
      ". formfieldone formfieldone ."
      ". formfieldtwo formfieldtwo ."
      ". formfieldthree formfieldfour .";
    row-gap: 15px;
    width: 350px;
    margin-bottom: 20px;
}

/* OVERLAY */

.OverlayContainer{
    position: sticky;
    top: 0;
    height: 0;
    z-index: 2;
}

.Overlay{
    height: calc(100vh + 100px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
}

/* HEADER */

.HeaderLogoContainer{
    height: 28px;
    width: 66px;
    grid-area: logo;
}

.HeaderLogo{
    height: 100%;
    margin-top: 13px;
    margin-left: 12px;
}

.HeaderContainer{
    z-index: 1;
    width: 100%;
    height: 0px;
    display: grid;
    text-align: center;
    position: sticky;
    grid-template-areas: '. . header . .';
    grid-template-columns: auto 75px 874px 75px auto;
    top: 0;
}

.HeaderContent{
    grid-area: header;
    background-color: rgb(0, 0, 0);
    height: 56px;
    z-index: 1;
    width: 874px;
    overflow: visible;
    text-align: center;
    display: grid;
    grid-template-areas: 'logo . navbar';
    grid-template-columns: 80px 468px auto;
}

.NavbarContainer{
    grid-area: navbar;
}

.NavbarContent{
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    text-align: center;
    display: grid;
    justify-items: center;
    align-items: center;
    padding: 0px;
    text-decoration: none;
    margin-bottom: 0px;
    margin-top: -7px;
}

.NavItem{
    margin-top: 28px;
    
}

.NavText:visited, .NavText:link{
    text-decoration: none;
}

.NavText:hover, .NavText:active{
    color: var(--everdevs-grn);
    cursor: default;
}

.NavText{
    font-family: ITC-Avant-Garde-Pro-Md;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.015em;
    padding: 0;
}

.NavText.Last{
    padding-right: 5px;
}

.NavLink.NavText, button.NavText{
    background-color: transparent;
    border: none;
    height: 14px;
}

.NavbarImage{
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.NavbarImageLetter{
    margin: 0;
    margin-top: 1px;
    margin-right: 7px;
    font-size: 12px;
}

.GridNavUsername{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
    'username dropdownarrow .'
    'dropdowncont dropdowncont dropdowncont';
    width: fit-content;
}

.NavUsername{
    grid-area: username;
    padding-left: 2px;
}

.NavDropdownBtn{
    grid-area: dropdownarrow;
    padding: 0;
}

.NavDropdownContainer{
    width: 100%;
    height: 0;
    grid-area: dropdowncont;
    padding-right: 15px;
    position: relative;
}

.NavDropdownContents{
    position: absolute;
    top: 20px;
    width: 100%;
    height: 56px;
}

.DropdownArrowContainer{
    height: 25px;
    width: 25px;
    text-align: center;
}

.DropdownArrow{
    padding-top: 11px;
}

.NavLogout{
    margin-left: 31px;
    margin-top: 21px;
}
/* PDF */

.PDFContainer{
    grid-area: content;
}

/* HOME */

.GridHome{
    display: grid;
    grid-template-columns: auto 350px auto;
    grid-template-areas:
    '. explanation .';
    grid-area: content;
    margin-bottom: 169px;
    margin-top: 225px;
}

.background-video {
    width: 1024px;
    height: 452px;
    object-fit: cover;
    grid-area: content;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.HomeTitle{
    text-align: center;
}

.HomeImageFader{
    background-color: black;
}

.HomeWrapper{
    min-width: 1024px;
}

.MainWrapper{
    background-position: center top;
    height: 452px;
    background-size: 1440px;
    background-repeat: no-repeat;
}

.HomeMissionContainer{
    width: 100%;
    min-width: 1024px;
    grid-area: content;
    display: grid;
    grid-template-columns: auto 611px auto;
    grid-template-areas:
    '. missionContent .';
}

.HomeMissionContent{
    width: 611px;
    height: 99px;
    align-items: center;
    text-align: center;
    grid-area: missionContent;
    margin-top: 45px;
    margin-bottom: 45px;
}

.TitleMissionStatement{
    margin-bottom: 0px;
}

.MissionStatement{
    margin-top: 0px;
}

/* GAMES */

.GridGames{
    display: grid;
    grid-template-rows: 32px 26px 20px 282px 90px;
    grid-template-columns: 80px 409px 104px 282px 149px;
    grid-template-areas:
    '. image . . .'
    '. image . title .'
    '. image . . .'
    '. image . description .'
    '. image . . .';
    grid-area: content;
    margin-top: 75px;
}

.GamesTitleContainer{
    grid-area: title;
}
.GamesDescriptionContainer{
    grid-area: description;
}
.GamesButtonContainer{
    grid-area: button;
}

.ImageGames{
    grid-area: image;
}

.GamesWrapper{
    background-position: center top;
    background-color: #68EDCB;
    min-width: 1024px;
    height: 400px;
    background-size: 1440px 400px;
    background-repeat: no-repeat;
}

.GamesTitle{
    margin: 0;
}

.GamesDescription{
    margin: 0;
}

/* DONATE */

.DonateWrapper{
    min-width: 1024px;
    background-color: #F4F5F6;
    height: 455px;
}

.DonateTitle{
    margin: 0;
}

.DonateDescription{
    margin: 0;
}

.DonateTitleContainer{
    grid-area: title;
}

.DonateDescriptionContainer{
    grid-area: description;
}

.DonateButtonContainer{
    grid-area: button;
    margin-bottom: 57px;
}

.ImageDonate{
    grid-area: image;
    width: 264px;
    margin-top: -55px;
}

.GridDonate{
    margin-bottom: 57px;
    margin-top: 86px;
    display: grid;
    grid-template-columns: 150px 282px 155px 264px 172px;
    grid-template-rows: 110px 166px auto;
    grid-template-areas:
    '. title . image .'
    '. description . image .'
    '. button . image .';
    grid-area: content;
}

/* CONTACT */


.ContactTitle{
    text-align: center;
    margin-top: 48px;
    margin-bottom: 9px;
    grid-area: title;
}

.ContactDescription{
    text-align: center;
    grid-area: description;
    margin-bottom: 38px;
    margin-top: 0px;
}

.GridContactExplanation{
    display: grid;
    grid-template-columns: auto 576px auto;
    grid-template-areas:
    '. title .'
    '. description .';
}

.GridDevStepsDiagram{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    grid-template-areas:
    '. . . . . . . arrowtop arrowtop arrowtop'
    'devstep0 devstep1 devstep2 devstep3 devstep4 devstep5 devstep6 devstep7 devstep8 devstep9'
    'line line line line line line line line line line'
    '. . . . . . . arrowbottom arrowbottom arrowbottom';
    column-gap: 32.1px;
    margin-left: 75px;
    margin-right: 75px; 
}

.ContactWrapper{
    min-width: 1024px;
}

.GridContact{
    display: grid;
    grid-area: content;
}

.FounderGrid{
    display: grid;
    grid-template-columns: auto 144px auto;
    grid-template-rows: 176px 33px 20px;
    grid-template-areas:
    '. image .'
    'name name name'
    'title title title';
    width: 222px;
}

.FounderImage{
    grid-area: image;
    text-align: center;
}

.FounderNameContainer{
    grid-area: name;
}

.FounderName{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.FounderTitleContainer{
    grid-area: title;
}

.FounderTitle{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.FounderKris{
    grid-area: kris;
}

.FounderMilton{
    grid-area: milton;
}

.FoundersGrid{
    display: grid;
    grid-template-columns: auto 222px 40px 222px auto;
    grid-template-areas:
    '. kris . milton .';
    margin-bottom: 60px;
}

/* ABOUT */

.GridAbout{
    grid-area: content;
    display: grid;
    grid-template-areas:
    '. explanation .'
    'story story story'
    'community community community'
    '. why .'
    'info info info';
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 56px;
}

.AboutTitle{
    margin-bottom: 20px;
}

.AboutWrapper{
    min-width: 1024px;
}

.AboutExplanation{
    text-align: center;
    width: 576px;
    grid-area: explanation;
}

.OurCommunityContainer{
    grid-area: community;
    text-align: center;
    margin-top: 20px;
}

.OurStoryContainer{
    grid-area: story;
    text-align: center;
    margin-top: 62px;
}

.WhyEverDevsContainer{
    grid-area: why;
    text-align: center;
    margin-top: 44px;
    margin-bottom: 22px;
}

.WhyEverDevsSubHeading{
    margin: 0;
}

.GridEverDevsInfoElement{
    border: 1px solid white;
    width: 209px;
    height: 140px;
}

.GridEverDevsInfo{
    grid-area: info;
    display: grid;
    grid-template-areas:
    'info0 info1 info2'
    'info3 info4 info5';
    column-gap: 50px;
    row-gap: 52px;
    margin-bottom: 60px;
}

.AboutSubHeading{
    margin: 0;
}

.AboutSubDescription{
    margin-top: 0;
    margin-bottom: 0;
}

.EverDevsIconContainer{
    margin-left: 14px;
    margin-top: 16px;
}

.EverDevsInfoTitle{
    margin-left: 14px;
    margin-top: 8px;
    margin-bottom: 0;
    font-family: ITC-Avant-Garde-Pro-Bold;
    font-size: 15px;
}

.EverDevsInfoDescription{
    margin-left: 14px;
    font-size: 15px;
    line-height: 16px;
    margin-top: 0;
    width: 180px;
}

/* FOOTER */

.FooterContainer {
    height: 300px;
    background-color: black;
    min-width: 1024px;
}

.GridFooter {
    grid-area: content;
    margin-left: 72px;
    margin-right: 72px;
    margin-top: 65px;
    display: grid;
    grid-template-areas:
    'logo stayconnected'
    'locations social'
    'copyright legal';
    grid-template-columns: 195px 685px;
    grid-template-rows: 58px 130px 15px;
}

.SocialIcon{
    margin-left: 10px;
}

.FooterLogoContainer{
    grid-area: logo;
}

.StayConnectedContainer{
    grid-area: stayconnected;
    margin-left: 575px;
}

.StayConnected{
    margin: 0;
    width: 109px;
    text-align: center;
    line-height: 24px;
}

.LocationsContainer{
    grid-area: locations;
    width: 77px;
}

.SocialContainer{
    grid-area: social;
    margin-left: 525px;
}

.CopyrightContainer{
    grid-area: copyright;
}

.LegalContainer{
    grid-area: legal;
    margin-left: 497px;
}

/* DASHBOARD */

#Dashboard{
    height: 0;
    width: 100%;
}
.DashboardWrapper{
    background-color: #E5E7E5;
    background-size: 100vw 100vh;
}
.DashboardContainer{
    grid-area: content;
    width: 874px;
    margin-left: 75px;
    margin-top: 95px;
    margin-bottom: max(50px, min(calc(100vh - 898px)));
}
.GridDashboardSections{
    margin-top: 48px;
    display: grid;
    column-gap: 14px;
    row-gap: 14px;
    grid-template-columns: 283px 282px 282px;
    grid-template-areas:
    'profile earnings openbriefs'
    'profile projects projects';
    height: 598px;
}
.DashboardProfileContainer{
    grid-area: profile;
    height: 598px;
}
.DashboardEarningsContainer{
    grid-area: earnings;
    height: 292px;
}
.DashboardOpenBriefsContainer{
    grid-area: openbriefs;
    height: 292px;
}
.DashboardProjectsContainer{
    grid-area: projects;
    height: 292px;
}
.DashboardSection{
    background-color: white;
}

/* DASHBOARD PROFILE */

.ProfileContainer{
    margin-left: 25px;
    margin-right: 25px;
}
.ProfileContents{
    height: 545px;
    text-align: center;
    hyphens: auto;
}
.ProfileImgContainer{
    margin-top: 20px;
}
.ProfileImg{
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
}
.ProfileImgReplacementContainer{
    display: inline-block;
}
.ProfileImgReplacementContents{
    width: 74px;
    height: 74px;
    border-radius: 40px;
    text-align: center;
}
.ProfileImgReplacementLetter{
    padding-top: 25px;
    font-family: ITC-Avant-Garde-Pro-Md;
    font-weight: 500;
}
.ProfileName{
    margin-top: 10px;
    margin-bottom: 0px;
}
.ProfileOrg{
    margin-top: 7px;
    margin-bottom: 0;
}
.ProfileRoleContainer{
    display: inline-block;
    margin-top: 6px;
}
.ProfileRoleContents{
    height: 24px;
    width: fit-content;
    border-radius: 14px;
}
.ProfileRole{
    padding-top: 7px;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
}
.ProfileLocationContainer{
    margin-top: 12px;
    margin-bottom: 16px;
    display: block;
}
.ProfileLocation{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.ProfileLocationText{
    margin: 0;
    font-family: ITC-Avant-Garde-Pro-Md;
    font-weight: 500;
}
.ProfileLocationIcon{
    position: absolute;
    top: 0;
    left: -8px;
}
.ProfileHorDiv{
    margin: 0;
}
.ProfileBioContainer{
    margin-top: 20px;
    margin-bottom: 20px;
}
.ProfileBioContents{
    max-height: 64px;
}
.ProfileBio{
    font-size: 14px;
    margin: 0;
}
.ProfileChannelsTitle{
    margin-top: 20px;
    margin-bottom: 12px;
}
.ProfileChannelIconsContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}
.ProfileChannelIconContainer{
    margin-left: 6.5px;
    margin-right: 6.5px;
}
.ProfileChannelsText{
    margin: 0;
}
.ProfileMemberSince{
    margin-top: 16px;
}
.ProfileEditBtnContainer{
    text-align: right;
    margin-top: 2px;
}
.ProfileEditBtn{
    text-decoration: none;
}
.ProfileOverlayContainer{
    height: 598px;
}

/* DASHBOARD EARNINGS */

.EarningsOverlayContainer{
    height: 292px;
}
.GridEarnings{
    margin: 20px;
    display: grid;
    grid-template-columns: auto 62px;
    grid-template-areas:
    'heading .'
    'points pointsicon'
    'pointstext pointsicon'
    'div div'
    'earnings .'
    'earningstext coinicon'
    'owed coinicon'
    'owedtext .';
}
.EarningsHeading{
    margin: 0;
    margin-bottom: 6px;
    grid-area: heading;
}
.EarningsPoints{
    grid-area: points;
    margin-top: 14px;
}
.EarningsPointsText{
    grid-area: pointstext;
    font-family: ITC-Avant-Garde-Pro-Md;
    font-weight: 500;
}
.EarningsPointsIconContainer{
    grid-area: pointsicon;
    height: 48px;
    margin-left: 2.5px;
}
.EarningsPointsIcon{
    width: 47px;
}
.EarningsHorDiv{
    grid-area: div;
    margin-top: 6px;
    margin-bottom: 0;
}
.EarningsTotal{
    grid-area: earnings;
    margin-top: 27px;
}
.EarningsTotalText{
    grid-area: earningstext;
    font-family: ITC-Avant-Garde-Pro-Md;
    font-weight: 500;
    margin-bottom: 23px;
}
.EarningsOwed{
    grid-area: owed;
}
.EarningsOwedText{
    grid-area: owedtext;
    font-family: ITC-Avant-Garde-Pro-Md;
    font-weight: 500;
    margin-bottom: 0;
    height: 8px;
}
.EarningsCoinIconContainer{
    grid-area: coinicon;
}
.EarningsCoinIcon{
    width: 48px;
    margin-top: 8px;
}

/* DASHBOARD OPEN BRIEFS */

.OpenBriefsOverlayContainer{
    height: 292px;
}
.OpenBriefsContainer{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 4px;
}
.OpenBriefsContents{
    height: 208px;
    padding-top: 12px;
}
.OpenBriefContainer{
    margin-bottom: 20px;
}
.OpenBriefHeaderContainer{
    display: flex;
}
.OpenBriefsHeading{
    margin-bottom: 0;
}
.OpenBriefHeading{
    margin-top: 2px;
}
.OpenBriefIcon{
    width: 27.5px;
    margin-right: 21.5px;
}
.OpenBriefPhase, .OpenBriefProject{
    margin: 0;
}
.OpenBriefDescription{
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 15px;
}
.OpenBriefBtnContainer{
    text-align: right;
}
.OpenBriefBtn{
    text-decoration: none;
}
.NoOpenBriefs{
    margin: 0;
}

/* DASHBOARD PROJECTS */

.ProjectsOverlayContainer{
    height: 292px;
}
.ProjectsContainer{
    margin: 20px;
}
.ProjectsHeading{
    margin: 0;
}
.ProjectsContents{
    height: 222px;
}
.ProjectContainer{
    display: flex;
    margin-top: 12px;
    margin-bottom: 25px;
}
.ProjectHeaderContainer{
    display: flex;
}
.ProjectIcon{
    width: 14px;
    height: 24px;
    margin-right: 20px;
}
.ProjectHeading{
    width: 317px;
    height: 28px;
}
.ProjectPhase{
    margin: 0;
}
.ProjectProject{
    margin: 0;
}
.ProjectStatusContainer{
    margin-left: auto;
    margin-top: 2px;
    width: 57px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
}
.ProjectStatus{
    margin-top: 7px;
    height: 10px;
}
.ProjectPoints{
    margin-top: 4px;
    margin-bottom: 0;
    margin-left: 0px;
    width: 124px;
    height: 10px;
    text-align: right;
    font-size: 14px;
}

/* EDIT PROFILE */

#EditProfile{
    height: 0;
    width: 100%;
}
.EditProfileWrapper{
    background-color: #E5E7E5;
    background-size: 100vw 100vh;
}
.EditProfileContainer{
    grid-area: content;
    width: 874px;
    margin-left: 75px;
    margin-top: 95px;
    margin-bottom: max(50px, min(calc(100vh - 898px)));
}
.EditProfileOverlayContainer{
    margin-top: 48px;
    background-color: white;
    height: 717px;
}
.GridEditProfileSections{
    margin-top: 48px;
    background-color: white;
    height: 717px;
    display: grid;
    grid-template-columns: 74px 322px 84px 322px;
    grid-template-areas:
    '. sectionone . sectiontwo';
}
.EditProfileSectionOne{
    grid-area: sectionone;
    z-index: 1;
}
.EditProfileSectionTwo{
    grid-area: sectiontwo;
    padding-top: 122px;
    z-index: 0;
}
.EditProfileImgContainer{
    display: flex;
    margin-top: 28px;
    margin-bottom: 27px;
    height: 74px;
}
.EditProfileUploadImgBtn{
    margin-left: 26px;
    margin-top: 20px;
}
.DeleteProfileImgBtnContainer{
    position: relative;
    width: 0;
    height: 0;
}
.DeleteProfileImgBtn{
    position: absolute;
    left: 57px;
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: #E9573F;
    border-radius: 10px;
    z-index: 2;
}
.DeleteProfileImgBtnX{
    color: white;
    margin-bottom: 1px;
}
.EditProfileFieldLabel{
    margin-top: 6px;
    margin-bottom: 6px;
}
.EditProfileFieldLong{
    width: 305px;
    padding-left: 15px;
    margin-bottom: 10px;
}
.PasswordVisibleContainer{
    width: 100%;
    height: 0;
    position: relative;
}
.PasswordVisibleBtn{
    right: 10px;
    margin-top: 10.5px;
    background-color: transparent;
    border: none;
    position: absolute;
}
.EditProfileEmailContainer{
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 16px;
}
.EditProfileEmail{
    margin: 0;
    padding-top: 10px;
}
.EditProfileLocationContainer{
    padding-top: 1px;
    padding-bottom: 1px;
}
.EditProfileLocationBtn{
    background-color: transparent;
    border: none;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
}
.LocationDropdownContainer{
    margin-top: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 200px;
    width: 290px;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.386);
}
.LocationDropdownContents{
    height: 200px;
}
.DropdownBtn{
    background-color: transparent;
    padding-bottom: 3px;
    border: none;
    width: 100%;
    text-align: left;
}
.DropdownBtn:hover{
    background-color: #d0f4d94d;
}
.DropdownBtn:focus, .DropdownBtn:visited, .DropdownBtn:active{
    outline: none !important;
    outline-style: none !important;
    box-shadow: none !important;
}
.CharCountContainer{
    position: relative;
}
.CharCount{
    position: absolute;
    right: 0;
    top: -35px;
    font-size: 14px;
}
.EditProfileDescription{
    height: 110px;
    width: 292px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    resize: none;
    border-radius: 17px;
    margin-bottom: 9px;
}
.ChannelLinkContainer{
    display: flex;
    margin-bottom: 16px;
}
.EditProfileFieldMedium{
    margin-left: auto;
    width: 250px;
    padding-left: 15px;
    padding-right: 15px;
}
.EditProfileFieldMedium::placeholder, .EditProfileFieldLong::placeholder{
    color: #848484;
}
.EditProfileFormBtnContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 38px;
}
.EditProfileBackBtn{
    margin-right: 14px;
    text-align: center;
    padding-top: 9px;
}
.EditProfileSubmitBtn{
    margin-right: 0;
}

/* PDF */

.PDFFrame{
    width: 100%;
    max-width: 725px; 
    height: 1000px; 
    margin-top: 106px;
    margin-bottom: 50px;
    margin-left: 150px;
    margin-right: 150px;
    border: none;
    grid-area: content;
}